import 'whatwg-fetch';
import React, {useState} from 'react';
import onClickOutside from 'react-onclickoutside';
import AutocompleteTitleItem from './AutocompleteTitleItem';

const AutocompleteTitle = ({data, triggerSubmit, text, setData}) => {
    const [value, setValue] = useState();
    const [events, setEvents] = useState();

    const handleChange = (e) => {
        var val = e.target.value;
        if (val === '') {
            setValue(val);
        } else {
            setValue(val);
            setData({...data, title: val, submit: true})
            callApiToAutocomplete(val);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            // setData({...data, title: value, submit: true});
            triggerSubmit();
        }
    }

    const handleItemClick = (e) => {
        const val = e.target.innerHTML;
        setValue(val);
        setData({...data, title: val, submit: true});
        triggerSubmit(true);
    }

    function buildAutocompleteUrl(value) {
        let url = `/social_events/autocomplete_title?q[title]=${value}`;
        return url;
    }

    function callApiToAutocomplete(value) {
        fetch(buildAutocompleteUrl(value), {
        method: 'GET'
        }).then(handleResponse);
    }

    const handleResponse = (res) => {
        if (res.ok) {
        res.json().then(handleData);
        }
    }

    const handleData = (res) => {
        setEvents(res);
    }

    return (
        <div className="autocomplete-wrap position-relative flex-grow-1">
            <div className="d-flex flex-row">
                <input
                    type="text"
                    name="q[title]"
                    // defaultValue={value}
                    autoComplete="off"
                    className="form-control py-3 ps-4"
                    style={{borderTopLeftRadius: 20, borderBottomLeftRadius: 20, borderRightWidth: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                    placeholder={text.title_placeholder}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange} />
                <div 
                    className="d-flex align-items-center justify-content-center px-3 border bg-white" 
                    style={{ 
                        borderTopLeftRadius: 0, 
                        borderBottomLeftRadius: 0, 
                        borderTopRightRadius: 20, 
                        borderBottomRightRadius: 20, 
                        borderLeftWidth: 0,
                        backgroundColor: data.submit ? '#ffa301' : '#212529'
                    }}
                    onClick={() => triggerSubmit(true)}
                >
                    <img src={"/assets/iconPark/search.svg"} width={30} height={30}/>
                </div>
            </div>
            <ul className="autocomplete-menu autocomplete-menu-search w-100 rounded bg-white">
                {events && 
                events.map((item, idx) => (
                    <AutocompleteTitleItem
                        key={idx}
                        index={idx}
                        name={item}
                        handleItemClick={handleItemClick} />)
                    )}
            </ul>
        </div>
    );

}

export default AutocompleteTitle;