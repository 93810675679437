'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import Filters from '../../src/components/Filters';

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('filter_container');
  const initialFilters = JSON.parse(container.getAttribute('data-filters'))
  const initialScope = JSON.parse(container.getAttribute('data-scope'))
  const unit = container.getAttribute('data-units')
  const translations = JSON.parse(container.getAttribute('data-translations'))

  function removeDeprecatedFilters(){
    const obj = initialFilters.table
    for (const key in obj) {
        if (obj.hasOwnProperty(key) && key.includes('downhill')) {
            delete obj[key];
        }
    }
    return obj;
  }

  const filters = removeDeprecatedFilters();
  const scope = initialScope.table;
  const transl = translations.table

  ReactDOM.render(
    <Filters filters={filters} scope={scope} unit={unit} translations={transl}/>,
    container
  )
})
