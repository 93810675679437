import React, {useEffect, useState, useRef} from 'react'
import CountrySelector from './CountrySelector';
import Pills from './Pills';
import FilterSlider from './FilterSlider';
import Helpers from './helpers/helpers'
import {isValidDate} from './lib/helpers';
import { DayPicker } from 'react-day-picker';
import { enGB } from "date-fns/locale";
import { DEFAULT_FILTERS } from './constants/constants';

const FilterElements = ({
    text, 
    showFilter, 
    toggleFilter, 
    data, 
    setData,
    triggerSubmit,
    submitEnabled
    }) => {
    const mainContainer = "d-flex flex-column gap-2 align-items-center"
    const filterContainer = "position-relative justify-content-center col-12"
    const columnWrap = "d-flex flex-column gap-2 flex-grow-1 px-lg-3"
    const absoluteWrapper = "align-self-stretch px-lg-5 px-3 pt-4 pb-3 d-flex flex-column gap-3 w-100 justify-content-start"
    const ref = useRef(null);
    const renderAllRef = useRef(null);

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (renderAllRef.current) {
            renderAllRef.current.scrollTo({
                top: scrollPosition
            });
        }
    },[data])

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            toggleFilter('');
        }
    }

    function handleScroll(){
        if (renderAllRef.current) {
            setScrollPosition(renderAllRef.current.scrollTop);
        }
    }

    const RenderFilter = ({children, standalone}) => {
        return(
            <div className={`${filterContainer} ${standalone ? 'col-lg-6' : 'col-lg-12'}`}>
                <div className={columnWrap}>
                    <div className={`${absoluteWrapper} ${standalone ? 'position-absolute bg-white rounded border align-items-center' : 'align-items-start'} `}>
                        {children}
                        {standalone && 
                            <button 
                                style={{fontSize: 14}}
                                className={`badge border btn-dark fw-light ${!submitEnabled ? 'disabled' : ''}`} 
                                onClick={() => submitEnabled && triggerSubmit(true)}>
                                    {text.confirm}
                            </button>
                        }
                    </div>
                </div>
            </div>
        )
    }

    function handleSelectDate(e){
        const newDate = new Date(e.from).toISOString().split('T')[0]
        setData({...data, start_date_from: newDate, submit: true})
    }

    const CountryFilter = ({standalone}) => {
        return (
            <RenderFilter 
                standalone={standalone!!}
                children={
                    <CountrySelector 
                        data={data}
                        standalone={standalone} 
                        setData={(a) => setData(a)}
                        text={text}
                    />} 
            />
        )
    }

    const DateFilter = ({standalone}) => {
        const initialValue = data.start_date_from ? new Date(data.start_date_from).toISOString().split('T')[0] : DEFAULT_FILTERS['start_date_from']
        const [startDate, setStartDate] = useState(initialValue);
        const today = new Date();
        const minDate = new Date(today.setFullYear(today.getFullYear() - 2)).toISOString().split('T')[0];
        const maxDate = new Date(today.setFullYear(today.getFullYear() + 2)).toISOString().split('T')[0];
        const [datePickerView, setDatePickerView] = useState()

        function handleDateChange(e){
            setStartDate(e.target.value);
            isValidDate(e.target.value) && setData({...data, start_date_from: e.target.value, submit: true})
        }
        
        return (
            <RenderFilter
                standalone={standalone!!}
                children={
                <div 
                    className="d-flex px-lg-5 px-0 flex-column align-self-start gap-2 flex-grow-1 align-self-stretch">
                    <div className="d-flex justify-content-between flex-grow-1 align-items-center">
                        <label style={{fontSize: 14}}>{text.date}</label>
                    </div>
                    <div className="d-flex flex-row gap-2">
                        <input 
                            className="form-control w-100" 
                            type="date" 
                            id="start"
                            defaultValue={startDate}
                            min={minDate}
                            max={maxDate}
                            onChange={(e) => handleDateChange(e)}
                            onKeyDown={(e) => {if (e.key === 'Enter') {
                                setData({...data, start_date_from: startDate, submit: true}); triggerSubmit(true)}
                            }}
                        />
                    </div>
                    <DayPicker
                    mode="range"
                    locale={enGB}
                    onSelect={(e) => 
                    handleSelectDate(e)}/>
                </div>
            }/>
        )
    }

    const DistanceFilter = ({standalone}) => {
        function resetFilters(){
            setData({
                ...data, 
                course_distance_from: DEFAULT_FILTERS['course_distance_from'],
                course_distance_to: DEFAULT_FILTERS['course_distance_from']
            });
        }

        return(
            <RenderFilter 
                standalone={standalone!!}
                children={
                <div className={`d-flex flex-column gap-2 ${!standalone ? 'px-lg-5 px-0' : ''}`}>
                    <div className="d-flex justify-content-between flex-grow-1 mb-2">
                        <label>{text.distance}</label>
                        <label className="text-decoration-underline" onClick={() => resetFilters()}>{text.reset_all}</label>
                    </div>
                    <Pills
                        text={text} 
                        param={'distance'} 
                        data={data} 
                        setData={(a) => {
                            setData(a);
                            handleScroll()
                        }} 
                    />
                </div>
            }/>
        )
    }

    const UphillFilter = ({standalone}) => {
        function resetFilters(){
            setData({
                ...data, 
                course_uphill_from: DEFAULT_FILTERS['course_uphill_from'],
                course_uphill_to: DEFAULT_FILTERS['course_uphill_from']
            });
        }

        return(
            <RenderFilter 
                standalone={standalone!!}
                children={
                    <div className={`d-flex flex-column gap-2 ${!standalone ? 'px-lg-5 px-0' : ''}`}>
                        <div className="d-flex justify-content-between flex-grow-1 mb-2">
                            <label>{text.uphill}</label>
                            <label className="text-decoration-underline" onClick={() => resetFilters()}>{text.reset_all}</label>
                        </div>
                        <Pills 
                            text={text}
                            param={'uphill'} 
                            data={data} 
                            setData={(a) => {
                                setData(a);
                                handleScroll()
                            }} 
                            />
                </div>
            }/>
        )
    }

    const PartnerFilter = ({standalone}) => {
        return(
            <RenderFilter 
                standalone={!!standalone}
                children={
                <div className="px-5">
                    <input 
                        className="form-check-input"
                        type="checkbox" 
                        id="flexCheckDefault"
                        defaultChecked={data.partner_events_only}
                        onChange={(e) => {
                            setData({...data, partner_events_only: e.target.checked, submit: true });
                        }} 
                    />
                    <label className="form-check-label ps-2">
                    {text.partner_event}
                    </label>
                </div>
            }/>
        )
    }

    const RenderAll = () => {
        return (
            <div 
                className="position-absolute mh-50 col-lg-6 bg-white rounded shadow-sm pb-5 mx-5 mx-lg-0"
                style={{minHeight: 300, overflowY: 'scroll'}}
                ref={renderAllRef}
                >
                <div className="d-flex flex-column align-items-center">
                    <RenderFilter 
                        standalone={false} 
                        children={
                            <div className="bg-white align-self-stretch d-flex justify-content-between align-items-center pb-2 border-bottom">
                                <label style={{fontSize: 14}} className="fw-light">{text.filter}</label>
                                <button style={{fontSize: 14}} className={`badge bg-dark text-light border ${!submitEnabled ? 'disabled' : ''}`} onClick={() => triggerSubmit(true)}>{text.search}</button>
                            </div>
                    }/>
                    <CountryFilter standalone={false}/>
                    <DateFilter standalone={false}/>
                    <DistanceFilter standalone={false}/>
                    <UphillFilter standalone={false}/>
                    <PartnerFilter standalone={false}/>   
                </div>
            </div>
        )
        
    }

    const showFilterOn = ['country', 'distance', 'uphill', 'date', 'partner_event', 'all']

    return(
        <div className={mainContainer} ref={ref}>
            {showFilter === 'country' && (
                <CountryFilter standalone/>
            )}
            {showFilter === 'distance' && (
                <DistanceFilter standalone/>
            )}
            {showFilter === 'uphill' && (
                <UphillFilter standalone/>
            )}
            {showFilter === 'date' && (
                <DateFilter standalone/>
            )}
            {showFilter === 'partner_event' && (
                <PartnerFilter standalone/>
            )}
            {showFilter === 'all' && (
                <RenderAll />
            )}
        </div>
    )
}

export default FilterElements;
