import React, { useState, useEffect } from "react";
import Selector from "./lib/selector";
import { COUNTRIES } from "./lib/countries";

const CountrySelector = ({data, setData, text, standalone}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [country, setCountry] = useState();

    useEffect(() => {
        if(data && data.country){
            setCountry(data.country)
        }
    },[])
    
    function handleOnChange(e){
        setCountry(e);
        setData({...data, country: e, submit: true})
    }

    return (
        <div
        className={"align-self-stretch bg-white rounded px-lg-3 py-2"}
        style={{maxHeight: 120}}
        >
            <div className={"px-lg-4"}>
                <label className="d-block ps-2 pb-2">
                    {text.select_a_country}
                </label>
                <Selector
                    id={"country-selector"}
                    open={isOpen}
                    text={text}
                    standalone={standalone}
                    onToggle={() => setIsOpen(!isOpen)}
                    onChange={(e) => handleOnChange(e)}
                    selectedValue={COUNTRIES.find((option) => option.value === country)}
                />
            </div>
        </div>
    );
}

export default CountrySelector;