import { COUNTRIES } from "./countries";
import React, { useEffect, useRef, useState } from "react";


export default function CountrySelector({
  id,
  open,
  disabled = false,
  onToggle,
  text,
  standalone,
  onChange,
  selectedValue,
}) {
  const ref = useRef(null);

  useEffect(() => {
    const mutableRef = ref
    const handleClickOutside = (event) => {
      if (
        mutableRef.current &&
        !mutableRef.current.contains(event.target) &&
        open
      ) {
        onToggle();
        setQuery("");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const [query, setQuery] = useState("");

  return (
    <div ref={ref}>
      <div 
        className="d-flex flex-column justify-content-between position-relative">
        <div
          className="fw-light d-flex align-self-stretch flex-row justify-content-between border rounded bg-white px-2 py-2 flex-grow-1 ps-3"
          onClick={onToggle}
        >
          {selectedValue && (
          <span className="truncate d-flex flex-row gap-2 fw-light">
            <img
              src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${selectedValue.value}.svg`}
              className={"inline mr-2 h-4 rounded-sm"}
              style={{width: 20, height: 20}}
            />
            {selectedValue.title}
          </span>
          )}
          {!selectedValue && text.search}
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="rgb(156 163 175)"
              aria-hidden="true"
              style={{width: 20, height: 20}}
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
        {(open || (!selectedValue && standalone)) && (
          <ul
            className={`bg-white rounded shadow-sm ${standalone ? 'position-absolute' : 'position-relative'} d-flex flex-wrap`}
            style={{maxHeight: '13rem', overflowY: 'scroll', top: standalone ? 45 : 10, zIndex: 1000}}
            tabIndex={-1}
            role="listbox"
            aria-labelledby="listbox-label"
            aria-activedescendant="listbox-option-3"
          >
            <div className="position-sticky w-100 top-0 bg-white flex-grow-1">
              <li 
                className="px-2 py-2 d-flex"
                style={{listStyle: 'none'}}
                >
                <input
                  type="search"
                  name="search"
                  autoComplete={"off"}
                  className="form-control"
                  placeholder={"Search a country"}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </li>
              <hr />
            </div>

            <div
              className={"flex-grow-1"}
            >
              {COUNTRIES.filter((country) =>
                country.title.toLowerCase().startsWith(query.toLowerCase())
              ).length === 0 ? (
                <li className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
                  No countries found
                </li>
              ) : (
                COUNTRIES.filter((country) =>
                  country.title.toLowerCase().startsWith(query.toLowerCase())
                ).map((value, index) => {
                  return (
                    <li
                      key={`${id}-${index}`}
                      className="d-flex flex-row gap-2 align-items-center py-2 ps-3"
                      id="listbox-option-0"
                      style={{listStyle: 'none'}}
                      role="option"
                      onClick={() => {
                        onChange(value.value);
                        setQuery("");
                        onToggle();
                      }}
                    >
                      <img
                        alt={`${value.value}`}
                        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${value.value}.svg`}
                        style={{width: 20, height: 20}}
                      />

                      <span className="fw-light">
                        {value.title}
                      </span>
                      {selectedValue && value.value === selectedValue.value ? (
                        <span className="">
                          <svg
                            style={{width: 20, height: 20}}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      ) : null}
                    </li>
                  );
                })
              )}
            </div>
          </ul>
        )}
      </div>
    </div>
  );
}
