'use strict';

const M_TO_KM = 0.001;
const M_TO_MI = 0.000621371;
const M_TO_FT = 3.28084;

export default class DistanceConverter {
  static metersToKilometers(distance) {
    const value = distance * M_TO_KM;
    return Math.round(value * 10) / 10;
  }

  static kilometersToMeters(distance) {
    return Math.round(distance / M_TO_KM);
  }

  static metersToMiles(distance) {
    const value = distance * M_TO_MI;
    return Math.round(value * 10) / 10;
  }

  static metersToFeets(distance) {
    const value = distance * M_TO_FT;
    return Math.round(value * 10) / 10;
  }
}
