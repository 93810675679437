import DistanceConverter from "../DistanceConverter";

export function metersToKilometers(value) {
    return DistanceConverter.metersToKilometers(value) + ' km';
  };
  
export function metersToMiles(value) {
    return DistanceConverter.metersToMiles(value) + ' mi';
  };
  
export function metersToFeets(value) {
    return DistanceConverter.metersToFeets(value) + ' ft';
  };
  
export function formatMeters(value) {
    return value + ' m';
  };

export function buildUrl(elem){

    let keys = [
        'title',
        'start_date_from',
        'start_date_to',
        'country',
        'partner_events_only',
        'course_distance_from',
        'course_distance_to',
        'course_uphill_from',
        'course_uphill_to'
    ];
  
    let url = `/events?q[title]=`;
  
    keys
      .forEach(function(key) {
        let name = `q[${key}]`;
        let value
        if (key == 'country') {
          value = document.getElementById('filterCountry').getAttribute('data-country');
        } else if (['partner_events_only'].includes(key)) {
          value = $(`input[name=\"${name}\"]:checked`).length ? true : false;
        } else {
          value = $(`input[name=\"${name}\"]`).val();
        }
        if ((key == 'course_distance_from' && elem.find((e) => e.key === 'course_distance_from')) ||(key == 'course_distance_to' && elem.find((e) => e.key === 'course_distance_to'))){
            value = elem.find((e) => e.key === key).value
        }
        if(value && (value !== undefined || value !== null )){
            url += `&${name}=${value}`;
        }
      });
    url += '&commit=Filter'
    return url;
  }

export function isValidDate(dateString) {
    // Define the date format you expect: mm-dd-YYYY
    const datePattern = /^\d{4}\-\d{2}\-\d{2}$/;

    // Check if the date string matches the expected format
    if (!dateString.match(datePattern)) {
        console.log(dateString, dateString.match(datePattern))
        return false;
    }

    // Parse the date components
    const [year, month, day] = dateString.split('-').map(Number);

    // Create a Date object from the parsed components
    const date = new Date(year, month - 1, day);

    // Check if the date is valid (handles cases like 02.30.2023)
    if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) {
        return false;
    }

    // Get today's date
    const today = new Date();

    // Calculate the min and max dates (-5 years and +5 years from today)
    const minDate = new Date();
    minDate.setFullYear(today.getFullYear() - 1);

    const maxDate = new Date();
    maxDate.setFullYear(today.getFullYear() + 2);

    // Check if the date is within the range
    if (date >= minDate && date <= maxDate) {
        return true;
    } else {
        return false;
    }
}
