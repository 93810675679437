import React, {useEffect, useState} from 'react';
import {COUNTRIES} from './lib/countries';
import { DISTANCES, ALTITUDES, DEFAULT_FILTERS } from './constants/constants';

const ActiveFilters = ({text, data, setData, triggerSubmit}) => {
    const [activeFilters, setActiveFilters] = useState();

    useEffect(() => {
        if(data){
            prepareData()
        }
    },[data])
    
    function prepareData(){
        const keysToKeep = ['country', 'title', 'event_partners_only'];
        const newObj = {...data}
        for (const key in newObj){
            if(key.includes('_min') || key.includes('_max') || key.includes('submit')){
                delete newObj[key] 
            } else if(!keysToKeep.includes(key) && Number(newObj[key]) === 0 ){
                delete newObj[key]
            } else if(key === 'partner_events_only' && !(!!newObj[key])){
                delete newObj[key]
            } else if(!(!!newObj[key])){
                delete newObj[key]
            }
        }
        const filters = setTags(newObj);
        setActiveFilters(filters)
    }

    function setTags(data){
        const today = new Date().toISOString().split('T')[0];
        const start_date = data.start_date_from && new Date(data.start_date_from).toISOString().split('T')[0];
        console.log()
        const newData = {...data}
        const distanceFrom = Number(newData['course_distance_from']);
        const distanceTo = Number(newData['course_distance_to']);
        const uphillTo = Number(newData['course_uphill_to']);
        const uphillFrom = Number(newData['course_uphill_from']);
        const newArray = [];
        for(const key in DISTANCES){
            if(DISTANCES[key].min >= distanceFrom && DISTANCES[key].max <= distanceTo){
                newArray.push({distance: key})
            }    
        }
        for(const key in ALTITUDES){
            if(ALTITUDES[key].min >= uphillFrom && ALTITUDES[key].max <= uphillTo){
                newArray.push({uphill: key})
            }
        }
        if(newData.country){
            const title = COUNTRIES.find(country => country.value === newData.country).title
            newArray.push({country: title})
        }
        if(newData.start_date_from && start_date !== today){
            newArray.push({start_date_from: newData.start_date_from})
        }
        if(newData.partner_events_only){
            newArray.push({partner_events_only: text.partner_event})
        }
        if(newData.title){
            newArray.push({title: newData.title})
        }
        return newArray;
    }

    function resetUphillDistance(key, value){
        const RANGE = key === 'distance' ? DISTANCES : ALTITUDES;
        let min = 0;
        let max = 0;
        const minActive = activeFilters
                .filter((flt) => flt[key])
                .map((flt) => RANGE[flt[key]].min)
        const maxActive = activeFilters
            .filter((flt) => flt[key])
            .map((flt) => RANGE[flt[key]].max)
        if(RANGE[value].min === Math.min(...minActive)){
            min = RANGE[value].max;
            max = Math.max(...maxActive);
        } else if (RANGE[value].max === Math.max(...maxActive)){
            min = Math.min(...minActive);
            max = RANGE[value].min;
        } else {
            min = Math.min(...minActive);
            max = RANGE[value].max;
        }
        return {
            ['course_distance_from']: min,
            ['course_distance_to']: max,
        }
    }

    function handleClick(key, value){
        let newKeys = {...data}
        if(key === 'distance' || key === 'uphill'){
            newKeys = resetUphillDistance(key, value)
        } else {
            newKeys = {
                [key]: resetValue(key), 
            }
        }
        const newData = {
            ...data,
            ...newKeys,
            submit: true
        }
        setData(newData);
        triggerSubmit(true);
    }

    function resetValue(key){
        return DEFAULT_FILTERS[key]
    }

    function handleReset(){
        setData({submit: true})
        triggerSubmit(true)
    }

    function setBackgroundColor(key, value){
        if(key === 'distance' || key === 'uphill'){
            const RANGE = key === 'distance' ? DISTANCES : ALTITUDES
            return RANGE[value].color
        } else {
            return '#212529'
        }
    }
    
    return (
        <div className="mt-2 d-flex justify-content-start ps-lg-4 ps-1"style={{minHeight: 50}}>
            {activeFilters && Object.keys(activeFilters).length > 0 && (
                <div className="gap-2 d-flex align-items-center flex-wrap">
                    <div className="d-flex flex-align-items-center flex-wrap gap-2">
                        {activeFilters.map((elem) => {
                            const [[key, value]] = Object.entries(elem);

                            return(
                                <label 
                                    className="d-flex align-items-center gap-2 badge text-light py-2 pe-pointer" 
                                    onClick={() => handleClick(key, value)}
                                    style={{fontSize: 13, backgroundColor: setBackgroundColor(key, value)}}
                                    key={value}
                                >
                                    {key === 'distance' || key === 'uphill' ? text[value] : value}
                                    <img src={"/assets/iconPark/_light/close.svg"} width={12} height={12}/>
                                </label>
                            )
                        })}
                    </div>
                    <div className="px-3 py-2 fw-light text-decoration-underline pe-pointer" onClick={() => handleReset()}>
                        {text.reset_all}
                    </div>
                </div>
            )}
        </div>
    )    
}

export default ActiveFilters;