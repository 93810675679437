const MAX_DISTANCE = 250001;
const MAX_UPHILL = 9801
const today = new Date();
const default_start_date = new Date(today.setDate(today.getDate() - 2)).toISOString().split('T')[0];

export const DISTANCES = {
    five_km: {min: 3000, max: 8000, title: 'five_km', color: "#55A7D5" },
    ten_km: {min: 8000, max: 12000, title: 'ten_km', color: "#71AF67"},
    ten_miles: {min: 12000, max: 18000, title: 'ten_miles', color: "#D9BE33"},
    half_marathon: {min: 18000, max: 25000, title: 'half_marathon', color: "#D9AA33"},
    twenty_miles: {min: 25000, max: 36000, title: 'twenty_miles', color: "#BD614D" },
    marathon: {min: 36000, max: 45000, title: 'marathon', color: "#8F3791" },
    ultra: {min: 45000, max: MAX_DISTANCE - 1, title: 'ultra', color: "#543791"}
  }

export const ALTITUDES = {
    flat: {min: 0, max: 50, title: 'flat', color: "#55A7D5"},
    some_climbing: {min: 50, max: 200, title: 'some_climbing', color: "#71AF67"},
    more_climbing: {min: 200, max: 500, title: 'more_climbing', color: "#D9BE33"},
    hilly: {min: 500, max: 1000, title: 'hilly', color: "#BD614D"},
    steep: {min: 1000, max: MAX_UPHILL - 1, title: 'steep', color: "#8F3791"}
}

export const DEFAULT_FILTERS = {
    country: '',
    start_date_from: default_start_date,
    start_date_to: '',
    title: '',
    course_distance_from: 0,
    course_distance_to: MAX_DISTANCE,
    course_uphill_from: 0,
    course_uphill_to: MAX_UPHILL,
    partner_events_only: false,
}